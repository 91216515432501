import { DOCUMENT, DecimalPipe, LowerCasePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation, booleanAttribute, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { APP_CONSTANTS, CURRENCY, CUSTOM_PLAN_CONFERENCE_TYPE, CUSTOM_PLAN_GROUP_APPOINTMENT_TYPE, CUSTOM_PLAN_STORE_TYPE, CUSTOM_PLAN_USER_TYPE, DECIMAL_DIGITS, DECIMAL_LOCALE, INTERVAL, LANGUAGES, PRICING_PLAN_PRECEDENCE, RENDER_MODE, SALES_INTERVAL, SUBSCRIPTION, SUBSCRIPTION_IDS, SUPPORTED_LANGUAGES } from '@constants/app.constants';
import { DASHBOARD_PRICING_JSON } from '@constants/dashboard.constants';
import { EXTRA_CONFERENCE_COST, EXTRA_GROUP_APPOINTMENT_COST, EXTRA_STORE_COST, EXTRA_USER_COST, MAX_LIMIT, MIN_CONFERENCE_LIMIT, MIN_LIMIT, SALES_TOKEN } from '@constants/pricing.constants';
import { SALES_PRICING_JSON } from '@constants/sales.constants';
import { WEBSITE_PRICING_JSON } from '@constants/website.constants';
import { CalioSafePipe } from '@core/pipes/calio-safe.pipe';
import { Currency, IntervalType, PricingDetails, RenderModeType, SalesIntervalType, SubscriptionType, SupportedLanguages } from '@db-models/pricing-db.model';
import { SubscriptionModel } from '@db-models/subscribe-db.model';
import { MaxInputValueDirective } from '@directives/max-input-value.directive';
import { environment } from '@env/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DomManipulationService } from '@services/util-service/dom-manipulation.service';
import { HelperService } from '@services/util-service/helper.service';
import { LoggerService } from '@services/util-service/logger.service';

@Component({
  selector: 'calenso-pricing-table',
  templateUrl: './calenso-pricing-table.component.html',
  styleUrl: './calenso-pricing-table.component.scss',
  standalone: true,
  imports: [LowerCasePipe, TranslateModule, NgClass, NgTemplateOutlet, FormsModule, DecimalPipe, CalioSafePipe, MaxInputValueDirective],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class CalensoPricingTableComponent implements OnInit {

  @Input('language') set lang(language: SupportedLanguages) {
    this.setlanguage(language);
  };

  @Input('render-mode') set RenderMode(renderMode: RenderModeType) {
    this._renderMode = Object.values(RENDER_MODE).includes(renderMode) ? renderMode : RENDER_MODE.WEBSITE;
    this.setPricingJson();
  };

  @Input('currency') set Currency(currency: Currency) {
    this.setCurrency(currency);
  };

  @Input('current-subscription') set setCurrentSubscription(currentSubscription: 'undefined' | 'null') {
    if (this._renderMode === RENDER_MODE.DASHBOARD && currentSubscription && (currentSubscription !== "undefined") && (currentSubscription !== "null")) {
      this._currentSubscription = JSON.parse(currentSubscription) as SubscriptionModel;
      // show currenctly active plan with interval
      this._interval = this._currentSubscription?.subscription_interval;
      this.currentPlanPrecedence = this.pricingPlanPrecedence[this._currentSubscription?.subscription_type_id];
      this.setCurrentSubscriptionChanges();
      this.isPartnerInTrialMode = !!this._currentSubscription.is_trial;
    }
  };

  @Input('subscription') set setSubscription(preSelectedSubscription: SubscriptionType) {
    if (this._renderMode === RENDER_MODE.DASHBOARD && Object.values(SUBSCRIPTION).includes(preSelectedSubscription)) {
      this._preSelectedSubscription = preSelectedSubscription;
      this._preSelectedInterval && this.setPreDefineSelectedPlan();
    }
  };

  @Input('interval') set setIntervals(preSelectedInterval: IntervalType) {
    if (this._renderMode === RENDER_MODE.DASHBOARD && Object.values(INTERVAL).includes(preSelectedInterval)) {
      this._preSelectedInterval = preSelectedInterval;
      this._preSelectedSubscription && this.setPreDefineSelectedPlan();
    }
  };

  @Input('popular') set setPopularPlan(popularPlan: SubscriptionType) {
    this.setPopular(popularPlan);
  };

  @Input('show-custom-plan-section') set setCustomPlanSection(showCustomPlan: string) {
    (showCustomPlan === 'true') && (this._showCustomPlanSection = true);
  };

  @Input('token') salesToken: string;

  @Input({ alias: 'show-more-interval', transform: booleanAttribute }) set showMoreInterval(moreInterval: boolean) {
    (moreInterval === true && this._renderMode === this.sales) && (this._showMoreInterval = true) && this.checkForSalesMode();
  };

  @Input({ alias: 'show-enterpriselite-plan', transform: booleanAttribute }) set showEnterpriseLitePlan(_showEnterpriseLitePlan: boolean) {
    (_showEnterpriseLitePlan === true && this._renderMode === this.sales) && (this._showEnterpriseLitePlan = true) && this.checkForSalesMode();
  };

  @Input({ alias: 'show-user-based-pricing', transform: booleanAttribute }) set showUserBasedPricing(_showUserBasedPricing: boolean) {
    (_showUserBasedPricing === true && this._renderMode === this.website) && (this._showUserBasedPricing = true);
    this.setPricingJson();
  };

  private helperService = inject(HelperService);
  private document = inject(DOCUMENT);
  private translate = inject(TranslateService);
  private domManipulationService = inject(DomManipulationService);

  private readonly dashboardPricingList = DASHBOARD_PRICING_JSON;
  private readonly websitePricingList = WEBSITE_PRICING_JSON;
  private readonly salesPricingList = SALES_PRICING_JSON;
  readonly deployUrl = environment.deployUrl
  readonly monthly = INTERVAL.MONTHLY;
  readonly yearly = INTERVAL.YEARLY;
  readonly year_2 = SALES_INTERVAL.YEAR_2;
  readonly year_3 = SALES_INTERVAL.YEAR_3;
  readonly website = RENDER_MODE.WEBSITE;
  readonly dashboard = RENDER_MODE.DASHBOARD;
  readonly sales = RENDER_MODE.SALES;
  readonly pricingPlanPrecedence = PRICING_PLAN_PRECEDENCE;
  readonly enterpriseId = SUBSCRIPTION_IDS.enterprise;
  readonly eur = CURRENCY.EUR;
  readonly chf = CURRENCY.CHF;
  readonly en_US = SUPPORTED_LANGUAGES.EN_US;
  readonly de_CH = SUPPORTED_LANGUAGES.DE_CH;
  readonly maxLimit = MAX_LIMIT;
  readonly minLimit = MIN_LIMIT;
  readonly minConferenceLimit = MIN_CONFERENCE_LIMIT;
  readonly extraUserCost = EXTRA_USER_COST;
  readonly extraGroupAppointmentCost = EXTRA_GROUP_APPOINTMENT_COST;
  readonly extraStoreCost = EXTRA_STORE_COST;
  readonly extraConferenceCost = EXTRA_CONFERENCE_COST;
  readonly customPlanUserType = CUSTOM_PLAN_USER_TYPE;
  readonly customPlanStoreType = CUSTOM_PLAN_STORE_TYPE;
  readonly customPlanGroupAppointmentType = CUSTOM_PLAN_GROUP_APPOINTMENT_TYPE;
  readonly customPlanConferenceType = CUSTOM_PLAN_CONFERENCE_TYPE;
  readonly decimalDigits = DECIMAL_DIGITS;
  readonly decimalLocal = DECIMAL_LOCALE;
  readonly subscriptionIds = SUBSCRIPTION_IDS;

  protected pricingList = WEBSITE_PRICING_JSON;
  protected currentPlanPrecedence: number;
  protected isCustomPlanSelectionEnabled = false;
  protected customPlanUser = this.minLimit;
  protected customPlanGroupAppointment = this.minLimit;
  protected customPlanStore = this.minLimit;
  protected customPlanConference = this.minConferenceLimit;
  protected customSelectedPlan: PricingDetails;
  protected appliedPlan: PricingDetails;
  protected customPlanTotalAmount: number;
  protected currencySymbol: string;
  protected isPartnerInTrialMode = false;
  protected availablePlans = [];
  protected showPricingSection = true;

  protected _language: SupportedLanguages;
  protected _currency: Currency;
  protected _renderMode: RenderModeType = RENDER_MODE.WEBSITE;
  protected _preSelectedSubscription: SubscriptionType;
  protected _currentSubscription: SubscriptionModel;
  protected _interval: IntervalType | SalesIntervalType = INTERVAL.YEARLY;
  protected _preSelectedInterval: IntervalType;
  protected _popularPlan: SubscriptionType;
  protected _showCustomPlanSection: boolean;
  protected _showMoreInterval: boolean;
  protected _showEnterpriseLitePlan: boolean;
  protected _showUserBasedPricing: boolean;

  constructor() {
    this.pricingList = this.helperService.setupMaxLimiteInPricingList(this.pricingList);
  }

  ngOnInit(): void {
    this.checkForSalesMode();

    LoggerService.log("[Pricing Page Inputs]", {
      "language": this._language,
      "currency": this._currency,
      "renderMode": this._renderMode,
      "preSelectedSubscription": this._preSelectedSubscription,
      "currentSubscription": this._currentSubscription,
      "interval": this._interval,
      "pricingList": this.pricingList,
      "token": this.salesToken,
      "_showEnterpriseLitePlan": this._showEnterpriseLitePlan,
      "_showUserBasedPricing": this._showUserBasedPricing,
    });

    this.validateInputs();
    this.pricingTableProjectLoaded();

    // Default custom plan is enabled then calculate price
    this.calculateCustomPlanPrice();

    if (environment.production) {
      setTimeout(() => {
        (this._renderMode === this.website) && this.domManipulationService.addVWOCodeScript();
      }, 2000);
    }
  }

  protected setlanguage(langauge?: SupportedLanguages): void {
    if ([SUPPORTED_LANGUAGES.DE_CH, SUPPORTED_LANGUAGES.EN_US].includes(langauge)) {
      this.translate.setDefaultLang(langauge);
    } else {
      const browserlang = this.translate.getBrowserCultureLang();
      if (browserlang && LANGUAGES.find(language => language.locale === browserlang.replace('-', '_'))) {
        this.translate.setDefaultLang(browserlang.replace('-', '_'));
      } else {
        this.translate.setDefaultLang(APP_CONSTANTS.DEFAULT_LANG);
      }
    }
    this._language = this.translate.getDefaultLang() as (SupportedLanguages);
  }

  protected setCurrency(currency?: Currency): void {
    if ([CURRENCY.CHF, CURRENCY.EUR].includes(currency)) {
      this._currency = currency;
    } else {
      this._currency = CURRENCY.EUR;
    }
    this.currencySymbol = (this._currency === this.chf) ? "CHF" : "€";
  }

  private setInterval(interval: IntervalType = INTERVAL.YEARLY): void {
    if ([INTERVAL.YEARLY, INTERVAL.MONTHLY].includes(interval)) {
      this._interval = interval;
    }
  }

  private setCurrentSubscriptionChanges(): void {
    this.setInterval(this._currentSubscription?.subscription_interval);
    (this._currentSubscription?.additional_users) && (this.changeUserProfile(this._currentSubscription.additional_users, this.customPlanUserType));
    (this._currentSubscription?.additional_stores) && (this.changeUserProfile(this._currentSubscription.additional_stores, this.customPlanStoreType));
    (this._currentSubscription?.additional_events) && (this.changeUserProfile(this._currentSubscription.additional_events, this.customPlanGroupAppointmentType));
    (this._currentSubscription?.additional_conferences) && (this.changeUserProfile(this._currentSubscription.additional_conferences, this.customPlanConferenceType));
  }

  private setPreDefineSelectedPlan(): void {
    if (this._preSelectedSubscription === SUBSCRIPTION.ENTERPRISE) {
      LoggerService.warn("Not able to upgrade to ENTERPRISE plan form UI, Need to contact support.");
      return;
    }

    if (this.currentPlanPrecedence === this.pricingPlanPrecedence?.[this.subscriptionIds?.[this._preSelectedSubscription]]) {
      if (this._currentSubscription.subscription_interval === this.monthly && this._preSelectedInterval === this.monthly) {
        LoggerService.warn("Current selected plan is already activated");
      } else if (this._currentSubscription.subscription_interval === this.monthly && this._preSelectedInterval === this.yearly) {
        LoggerService.log("Current plan is interval upgrade");
        const plan = this.pricingList.find(planDetails => planDetails.name.toUpperCase() === this._preSelectedSubscription?.toUpperCase());
        this.submit(plan, this._preSelectedInterval, this._currency);
      }
    } else if (this.currentPlanPrecedence < this.pricingPlanPrecedence?.[this.subscriptionIds?.[this._preSelectedSubscription]]) {
      LoggerService.log("Upgrade plan");
      const plan = this.pricingList.find(planDetails => planDetails.name.toUpperCase() === this._preSelectedSubscription?.toUpperCase());
      this.submit(plan, this._preSelectedInterval, this._currency);
    }
  }

  private setPopular(popularPlan: SubscriptionType): void {
    if (Object.values(SUBSCRIPTION).includes(popularPlan)) {
      this._popularPlan = popularPlan;
    }
  }

  private validateInputs(): void {
    !this._language && this.setlanguage();
    !this._currency && this.getCurrentFromIp();
    !this._interval && this.setInterval();
  }

  private checkForSalesMode(): void {
    const isPricePageDomain = (window.location.href).includes(this.deployUrl);
    let paramsObject;
    if (isPricePageDomain) {
      paramsObject = this.helperService.getParamsFromUrl(new URL(window.location.href));
    }
    // Check for web component view
    if (this._renderMode && !paramsObject) {
      // Check for sales web component view
      if (this._renderMode === this.sales && this.salesToken === SALES_TOKEN) {
        this._showCustomPlanSection = true;
      }
    } else {
      // Check for sales url support
      if (paramsObject?.mode === this.sales && paramsObject?.token === SALES_TOKEN) {
        // if mode and token are set, set mode to "sales" and shows a custom plan section
        this._renderMode = RENDER_MODE.SALES;
        this._showCustomPlanSection = true;
        paramsObject?.language && this.setlanguage(paramsObject.language);
        paramsObject?.currency && this.setCurrency(paramsObject.currency);
        paramsObject?.interval && this.setInterval(paramsObject.interval);
        paramsObject?.popular && this.setPopular(paramsObject.popular);
        (paramsObject?.showMoreInterval === "true") && (this._showMoreInterval = true);
        this._showEnterpriseLitePlan = (paramsObject?.showEnterpriseLitePlan === 'true');
      } else {
        this._renderMode = RENDER_MODE.WEBSITE;
      }
    }
    this.setPricingJson();
    !this._showEnterpriseLitePlan && this.removeEnterpriseLitePlan();
  }

  private removeEnterpriseLitePlan(): void {
    // if mode and token are not set, remove ENTERPRISELITE from pricing list
    const findEnterpriseLite = this.pricingList.findIndex(plan => plan.name === SUBSCRIPTION.ENTERPRISELITE);
    (findEnterpriseLite >= 0) && this.pricingList.splice(findEnterpriseLite, 1);
  }

  protected toggleInterval(toggle: IntervalType | SalesIntervalType): void {
    this._interval = toggle;
    this.calculateCustomPlanPrice();
  }

  protected submit(priceModel: PricingDetails, interval: IntervalType | SalesIntervalType, currency: Currency): void {
    LoggerService.log("[Info] Selected plan", priceModel);
    LoggerService.log("[Info] Selected interval", interval);
    LoggerService.log("[Info] Selected currency", currency);
    let customPlan = {};
    if (priceModel?.min_workers < this.customPlanUser) {
      customPlan[this.customPlanUserType] = this.customPlanUser - priceModel.min_workers;
    }
    if (priceModel?.min_store < this.customPlanStore) {
      customPlan[this.customPlanStoreType] = this.customPlanStore - priceModel.min_store;
    }
    if (priceModel?.min_group_appointment < this.customPlanGroupAppointment) {
      customPlan[this.customPlanGroupAppointmentType] = this.customPlanGroupAppointment - priceModel.min_group_appointment;
    }
    if (priceModel?.min_conference < this.customPlanConference) {
      customPlan[this.customPlanConferenceType] = this.customPlanConference - priceModel.min_conference;
    }
    LoggerService.log("[Info] Selected customPlan", customPlan);
    const customEvent = new CustomEvent('pricingTablePlanSelectEvent', { detail: { priceModel, interval, currency, customPlan } });
    this.document.dispatchEvent(customEvent);
  }

  protected compareFeatureList(): void {
    const customEvent = new CustomEvent('pricingTableCompareFeatureEvent');
    this.document.dispatchEvent(customEvent);
  }

  private pricingTableProjectLoaded(): void {
    const customEvent = new CustomEvent('pricingTableLoadedEvent');
    this.document.dispatchEvent(customEvent);
  }

  protected toggleUserProfile(): void {
    this.isCustomPlanSelectionEnabled = !this.isCustomPlanSelectionEnabled;
  }

  protected changeUserProfile(customNumber: number, flag: string): void {
    if (flag === this.customPlanUserType) {
      (customNumber < this.minLimit) && (this.customPlanUser = this.minLimit);
    } else if (flag === this.customPlanGroupAppointmentType) {
      (customNumber < this.minLimit) && (this.customPlanGroupAppointment = this.minLimit);
    } else if (flag === this.customPlanStoreType) {
      (this.customPlanStore < this.minLimit) && (this.customPlanStore = this.minLimit);
    } else if (flag === this.customPlanConferenceType) {
      if (customNumber === 0) {
        this.customPlanConference = 0;
      } else if (customNumber < 5) {
        this.customPlanConference = 5;
      }
    }
    this.calculateCustomPlanPrice();
  }

  private applyCustomProfileToPricingTable(): void {
    this.appliedPlan = this.customSelectedPlan;
  }

  protected cancel(): void {
    this.customPlanUser = 1;
    this.customPlanGroupAppointment = 1;
    this.customPlanStore = 1;
    this.customPlanConference = 0;
    this.appliedPlan = undefined;
    this.calculateCustomPlanPrice();
    this.toggleUserProfile();
  }

  protected decrement(flag: string): void {
    if (flag === this.customPlanUserType) {
      (this.customPlanUser >= this.minLimit) && this.customPlanUser--;
    } else if (flag === this.customPlanGroupAppointmentType) {
      (this.customPlanGroupAppointment >= this.minLimit) && this.customPlanGroupAppointment--;
    } else if (flag === this.customPlanStoreType) {
      (this.customPlanStore >= this.minLimit) && this.customPlanStore--;
    } else if (flag === this.customPlanConferenceType) {
      if (this.customPlanConference === 5) {
        this.customPlanConference = 0;
      } else if (this.customPlanConference >= this.minLimit) {
        this.customPlanConference--;
      }
    }
    this.calculateCustomPlanPrice();
  }

  protected increment(flag: string): void {
    if (flag === this.customPlanUserType) {
      (this.customPlanUser < this.maxLimit) && this.customPlanUser++;
    } else if (flag === this.customPlanGroupAppointmentType) {
      (this.customPlanGroupAppointment < this.maxLimit) && this.customPlanGroupAppointment++;
    } else if (flag === this.customPlanStoreType) {
      (this.customPlanStore < this.maxLimit) && this.customPlanStore++;
    } else if (flag === this.customPlanConferenceType) {
      if (this.customPlanConference === 0) {
        this.customPlanConference = 5;
      } else if (this.customPlanConference < this.maxLimit) {
        this.customPlanConference++;
      }
    }
    this.calculateCustomPlanPrice();
  }

  private calculateCustomPlanPrice(): void {
    let preapredCustomPlan: PricingDetails;
    this.availablePlans = [];
    this.pricingList.forEach(plan => {
      if (
        plan.max_workers >= this.customPlanUser &&
        plan.max_store >= this.customPlanStore &&
        plan.max_group_appointment >= this.customPlanGroupAppointment &&
        plan.max_conference >= this.customPlanConference
      ) {
        this.availablePlans.push(plan.id);
      }
    });
    // here selecting the first available plan as default plan becuase we are showing it with precedence.
    preapredCustomPlan = this.pricingList.find(plan => plan.id === this.availablePlans[0]);
    this.customSelectedPlan = preapredCustomPlan;
    this.updateCustomFinalPlanTotal();
  }

  private updateCustomFinalPlanTotal(): void {
    let customPlanTotal = 0;
    const additionPlanMonthlyYearlyMultiplier = (this._interval === this.yearly) ? 1 : 1;
    customPlanTotal = +this.customSelectedPlan?.[this._currency?.toLowerCase()]?.[this._interval]?.original_price * additionPlanMonthlyYearlyMultiplier;
    if (this.customSelectedPlan.min_workers < this.customPlanUser) {
      customPlanTotal = customPlanTotal + ((this.customPlanUser - this.customSelectedPlan.min_workers) * this.extraUserCost * additionPlanMonthlyYearlyMultiplier);
    }
    if (this.customSelectedPlan.min_store < this.customPlanStore) {
      customPlanTotal = customPlanTotal + ((this.customPlanStore - this.customSelectedPlan.min_store) * this.extraStoreCost * additionPlanMonthlyYearlyMultiplier);
    }
    if (this.customSelectedPlan.min_group_appointment < this.customPlanGroupAppointment) {
      customPlanTotal = customPlanTotal + ((this.customPlanGroupAppointment - this.customSelectedPlan.min_group_appointment) * this.extraGroupAppointmentCost * additionPlanMonthlyYearlyMultiplier);
    }
    if (this.customSelectedPlan.min_conference < this.customPlanConference) {
      customPlanTotal = customPlanTotal + ((this.customPlanConference - this.customSelectedPlan.min_conference) * this.extraConferenceCost * additionPlanMonthlyYearlyMultiplier);
    }
    this.customPlanTotalAmount = customPlanTotal;
    this.applyCustomProfileToPricingTable();
  }

  private getCurrentFromIp(): void {
    this.setCurrency(CURRENCY.CHF);
    this.helperService.getCurrentFromIp().subscribe({
      next: ipInfo => this.setCurrency(ipInfo?.country === 'CH' ? CURRENCY.CHF : CURRENCY.EUR),
      error: () => this.setCurrency(CURRENCY.CHF)
    })
  }

  private setPricingJson(): void {
    let tempPricingList: PricingDetails[];
    if (this._renderMode === this.dashboard) {
      tempPricingList = structuredClone(this.dashboardPricingList);
    } else if (this._renderMode === this.sales) {
      tempPricingList = structuredClone(this.salesPricingList);
    } else {
      tempPricingList = structuredClone(this.websitePricingList);
    }
    this.pricingList = this.helperService.setupMaxLimiteInPricingList(tempPricingList, this._showUserBasedPricing);

    this._renderMode === this.website && (this.showPricingSection = false);
  }
}

